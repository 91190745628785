<template>
  <div id="parents" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-12">
          <SdInput
            type="text"
            label="Search"
            v-model="searchName"
            placeholder="Name"
          />
        </div>
        <div class="column is-12">
          <p v-if="error">{{ error.message }}</p>
          <b-table
            :data="data.length > 0 ? data : parents"
            :paginated="isPaginated"
            hoverable
          >
            <b-table-column
              field="#"
              label="#"
              width="40"
              numeric
              v-slot="props"
              >{{ parents.indexOf(props.row) + 1 }}</b-table-column
            >

            <b-table-column label="Name of Father" v-slot="props"
              >{{ props.row.familyName }}
              {{ props.row.nameOfFather }}</b-table-column
            >

            <b-table-column label="Name of Mother" v-slot="props"
              >{{ props.row.familyName }}
              {{ props.row.nameOfMother }}</b-table-column
            >

            <b-table-column label="Student Count" v-slot="props">{{
              props.row.studentsCount
            }}</b-table-column>

            <b-table-column label="Activated" v-slot="props">
              <figure class="image is-24x24">
                <img
                  src="@/assets/images/square-green.png"
                  alt="square-green"
                  v-if="parentUsers(props.row.users).length === 2"
                />
                <img
                  src="@/assets/images/square-orange.png"
                  alt="square-orange"
                  v-else-if="parentUsers(props.row.users).length === 1"
                />
                <img
                  src="@/assets/images/square-red.png"
                  alt="square-red"
                  v-else
                />
              </figure>
            </b-table-column>

            <b-table-column label="Actions" width="160" v-slot="props">
              <action-buttons
                @initialize-info="initializeInfo(props.row)"
                @initialize-update="initializeUpdate(props.row)"
                @initialize-remove="initializeRemove(props.row)"
              />
            </b-table-column>
          </b-table>
        </div>
      </div>
    </template>
    <!-- Delete -->
    <app-modal
      id="remove-parent"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
const randomstring = require('randomstring')
import SdInput from '@/components/SdInput'
import { crudNotification } from '@/assets/js/notification'

export default {
  name: 'parents',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Parents',
      data: [],
      parents: [],
      id: null,
      error: null,
      isPaginated: true,
      searchName: '',
    }
  },
  watch: {
    searchName(query) {
      if (query === '') {
        this.data = []
        return
      }

      const searchByFatherName = (parent) => {
        const even = (element) =>
          element.toLowerCase().match(query.toLowerCase())
        return parent.nameOfFather.split(' ').some(even)
      }

      const searchByMotherName = (parent) => {
        const even = (element) =>
          element.toLowerCase().match(query.toLowerCase())
        return parent.nameOfMother.split(' ').some(even)
      }

      const searchByPhoneNumber = (parent) => {
        if (parent.phoneOfFather || parent.phoneOfMother) {
          return [parent.phoneOfFather, parent.phoneOfMother].includes(query)
        } else {
          return false
        }
      }

      this.data = this.parents.filter(
        (parent) =>
          searchByFatherName(parent) ||
          searchByMotherName(parent) ||
          searchByPhoneNumber(parent)
      )
    },
  },
  methods: {
    parentUsers(users) {
      const _users = users.filter((user) => user.firebaseUserId.length > 10)
      return _users
    },
    initializeInfo(parent) {
      this.$router.push(`/school/${this.schoolId}/parent_info/${parent.id}`)
    },
    initializeUpdate(parent) {
      this.$router.push(`/school/${this.schoolId}/parent/${parent.id}`)
    },
    initializeRemove(parent) {
      this.id = parent.id
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteParentQuery($id: Int!) {
              deleteParent(input: { id: $id }) {
                parent {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.id),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteParent.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.parents.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  components: {
    SdInput,
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'New',
        route: `/school/${this.schoolId}/parent/new`,
      },
      {
        name: 'Bulk Upload',
        route: `/school/${this.schoolId}/parents_bulk_upload`,
      },
      // {
      //   name: 'Pdf',
      //   route: `/school/${this.schoolId}/parent/new`,
      // },
      // {
      //   name: 'Excel',
      //   route: `/school/${this.schoolId}/parent/new`,
      // },
    ])

    this.$apollo.addSmartQuery('parents', {
      query: gql`
        query Parents($schoolId: Int!) {
          parents(schoolId: $schoolId) {
            id
            familyName
            nameOfFather
            nameOfMother
            studentsCount
            phoneOfFather
            phoneOfMother
            users {
              id
              firebaseUserId
            }
          }
        }
      `,
      error(error) {
        this.error = error.message
      },
      variables() {
        return { schoolId: this.schoolId }
      },
    })

    this.$apollo.queries.parents.refetch()
  },
}
</script>
